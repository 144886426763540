<template>
  <ori-card-wrap
    class="match-card"
    zhTitle="大赛活动"
    enTitle="Competitionactivities"
    desc="全国创新创业大赛活动一键直通"
  >
    <span slot="more">查看更多></span>
    <template>
      <ul class="match-list">
        <li v-for="(i, index) in list" :key="i.id" v-show="index < 6">
          <div class="solo-row title hover-red">
            {{ i.title }}
          </div>
          <div class="desc">
            {{ i.desc }}
          </div>
          <div class="qy-wrap">
            <div>参赛权益：</div>
            <div class="solo-row">
              {{ i.qyTag.slice(0, 4).join("、") }}
            </div>
          </div>
          <div class="time-city">
            <div>报名时间：</div>
            <div>
              <span>
                {{ i.start.split(" ")[0] }} 至 {{ i.start.split(" ")[0] }}
              </span>
              <span>{{ i.city }}</span>
            </div>
          </div>
          <div class="price-bottom">
            <span> ￥{{ i.price }}万 </span>
            <span> 了解更多> </span>
          </div>
        </li>
      </ul>
    </template>
  </ori-card-wrap>
</template>

<script>
import { matchCardData } from "@/Mock";
export default {
  name: "match-card",
  data() {
    return {
      list: matchCardData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.match-card {
  .match-list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    li {
      cursor: pointer;
      width: 389px;
      height: 270px;
      background: #ffffff;
      transition: all 0.5s ease-in;
      padding: 20px;
      margin-right: 15px;

      &:hover,
      &:active {
        box-shadow: 0px 0px 20px 0px rgb(206 206 206 / 17%);
        transform: translateY(-5px);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 20px;
      }

      .title {
        width: 350px;
        font-size: 20px;
        font-weight: 700;
      }

      .desc {
        width: 350px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 5px;
      }

      .qy-wrap,
      .time-city {
        margin-top: 10px;
        font-size: 12px;
        div:nth-child(1) {
          color: #999;
          font-weight: bold;
        }
        div:nth-child(2) {
          margin-top: 5px;
          width: 350px;
          color: #4c495d;
        }
      }

      .time-city {
        margin-top: 15px;
        div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .price-bottom {
        margin-top: 10px;
        border-top: 1px dashed #f4f5f9;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:nth-child(1) {
          font-size: 19px;
          font-weight: 700;
          line-height: 32px;
          color: #e62d31;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 113px;
          height: 32px;
          background: #e62d31;
          line-height: 32px;
          text-align: center;
          border-radius: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>