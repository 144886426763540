<template>
	<div class="ori-result">
		<ori-header />

		<match-filter />

		<div class="result-wrap" @click="$router.push('/result-detail')">
			<div class="result-content w1200">
				<div class="left">
					<div class="item-box" v-for="i in list" :key="i.id">
						<el-row :gutter="20">
							<el-col :span="8">
								<div class="title">
									{{ i.title }}
								</div>
							</el-col>
							<el-col :span="8">
								<div class="price">
									￥{{ i.lowPrice }} - ￥{{ i.heightPrice }}
								</div>
							</el-col>
						</el-row>
						<el-row class="row-2" :gutter="20" type="flex" align="middle">
							<el-col :span="8">
								<div class="t-tags">
									<span class="tag">
										{{ i.typeTag }}
									</span>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="p-tag">
									<span>终端版本：</span>
									<span>{{ i.ptTag }}</span>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="btn-wrap">
									<el-button size="medium">申请合作</el-button>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="row-3">
							<el-col :span="8">
								<div class="status">
									<span>已有3人申请合作</span>
									<span>2021-08-28 发布</span>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="area">
									<span>地域要求：</span>
									<span>无</span>
								</div>
							</el-col>
						</el-row>
					</div>

					<div class="pagination-wrap">
						<ori-pagination />
					</div>
				</div>
				<div class="right">
					<div class="head">热门需求</div>

					<div class="items1">
						<div
							class="box"
							v-for="(i, index) in list"
							:key="i.id"
							v-show="index < 3"
						>
							<div class="title">
								{{ i.title }}
							</div>
							<div class="ly-tag">
								<span>项目领域：</span>
								<span>{{ i.typeTag }}</span>
							</div>
							<div class="price-time">
								<span> ￥{{ i.lowPrice }} - ￥{{ i.heightPrice }} </span>
								<span>
									<i class="el-icon-time"></i>
									{{ i.count }} 天前</span
								>
							</div>
						</div>
					</div>

					<div class="head">最热产品</div>

					<div class="items2">
						<div
							class="box"
							v-for="(i, index) in list"
							:key="i.id"
							v-show="index < 6"
						>
							<div class="img">
								<img :src="i.poster" alt="" srcset="" />
							</div>
							<div class="title">
								<span>
									<i class="el-icon-mobile-phone"></i>
								</span>
								<span>{{ i.title }}</span>
								<span>
									<i class="el-icon-medal-1"></i>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<match-card style="margin-bottom:40px"/>
		<page-foot />
	</div>
</template>

<script>
import { resultData } from '@/Mock'
import MatchCard from "../../../components/ori-com/MatchCard.vue";
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import MatchFilter from '../../../components/ori-com/Select/MatchFilter.vue'
export default {
	components: { PageFoot, MatchFilter, MatchCard },
	data() {
		return {
			list: resultData.list,
		}
	},
}
</script>

<style lang="scss" scoped>
.ori-result {
	width: 100%;
	overflow: hidden;
	background-color: #f9f9f9;

	.result-wrap {
		width: 100%;
		overflow: hidden;

		.result-content {
			margin-top: 20px;

			display: flex;
			align-items: flex-start;

			.left {
				flex: 1 1 auto;

				background-color: #fff;
				margin-bottom: 30px;

				.pagination-wrap {
					display: flex;
					justify-content: center;
					padding-bottom: 40px;
					padding-top: 50px;
				}

				.item-box {
					margin: 0 30px;

					padding-top: 23px;

					line-height: 1;

					& + .item-box {
						border-top: 1px dashed #ddd;
					}

					.title {
						font-size: 16px;
						font-weight: bold;
						white-space: nowrap;
						text-overflow: ellipsis;
						display: inline-block;
						max-width: 280px;
						overflow: hidden;
					}

					.price {
						color: #f43337;
						font-size: 20px;
					}

					.row-2 {
						margin-top: 10px;
					}

					.t-tags {
						.tag {
							border-radius: 3px;
							color: #999;
							font-size: 12px;
							background-color: #f4f5f9;
							padding: 6px 10px;
							margin-right: 8px;
							margin-bottom: 5px;
						}
					}

					.p-tag {
						span:nth-child(1) {
							color: #999;
							font-size: 12px;
						}
						span:nth-child(2) {
							color: #666;
							font-size: 12px;
						}
					}

					.btn-wrap {
						text-align: right;
					}

					.row-3 {
						padding-top: 10px;
						padding-bottom: 20px;
					}

					.status {
						color: #999;
						font-size: 12px;
						span:nth-child(1) {
							border-right: 1px solid #999;
							padding-right: 15px;
						}
						span:nth-child(2) {
							padding-left: 15px;
						}
					}

					.area {
						font-size: 12px;
						color: #999;
						span:nth-child(2) {
							color: #666;
						}
					}
				}
			}

			.right {
				flex: 0 0 auto;
				width: 330px;
				margin-left: 10px;

				.head {
					height: 46px;
					line-height: 46px;
					background-color: #fff;
					font-size: 16px;
					color: #000;
					font-weight: bold;
					padding-left: 16px;
				}

				.items1,
				.items2 {
					background-color: #fff;
					margin-top: 22px;
					padding: 0 14px;
					overflow: hidden;
					margin-bottom: 20px;
				}

				.items1 {
					.box + .box {
						border-top: 1px dashed #ddd;
					}
					.box {
						padding-top: 15px;
						.title {
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 10px;
						}
						.ly-tag {
							color: #666;
							font-size: 12px;
							display: block;
							overflow: hidden;
							height: 18px;
							line-height: 18px;
							text-overflow: ellipsis;
							white-space: nowrap;
							margin-bottom: 10px;
						}

						.price-time {
							margin-bottom: 10px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							span:nth-child(1) {
								color: #f43337;
								font-size: 14px;
								font-weight: bold;
							}
							span:nth-child(2) {
								font-size: 14px;
								color: #999;
							}
						}
					}
				}

				.items2 {
					.box + .box {
						border-top: 1px solid #ddd;
					}
					.box {
						width: 240px;
						margin: 0 auto;
						padding-top: 45px;

						.img {
							width: 240px;
							height: 180px;
							overflow: hidden;
							img {
								transition: all 0.8s ease-in;
							}
						}

						.title {
							display: flex;
							align-items: center;
							margin: 10px 0;
							span:nth-child(2) {
								display: inline-block;
								padding: 0 8px;
								font-size: 14px;
								font-weight: bold;
								// margin-top: 10px;
								overflow: hidden;
								max-width: 170px;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
							span {
								i {
									font-size: 16px;
									color: red;
									// vertical-align: top;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
